import React, { useState } from "react";
import { Button, IconButton, Popover, Typography } from "@mui/material";
import { popoverStyle, popoverContainerStyle, popoverHeaderStyle, popoverHeaderTitleContainerStyle, popoverBodyStyle, glassButtonStyle, centerAlignSubBoxStyle, twoBlockBox } from "../../styles/general";
import { Close, AccountCircle } from "@mui/icons-material";
import AttendSection from "./attendSection";
import SignatureSection from "./signatureSection";
import TimekeeperSection from "./timekeeperSection";
import LogoutButton from "./logoutButton";

const transform = {
    vertical: "top",
    horizontal: "right"
  };

const anchorOrigin = {
    vertical: "bottom",
    horizontal: "left"
  };

export default function AccountPopover({context, anchor, setAnchor}) {
    const [timekeeper, setTimekeeper] = useState(false);

    const open = Boolean(anchor);
    const id = open ? "clock-popover" : undefined;

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchor}
            onClose={() => {setAnchor(null)}}
            transformOrigin={transform}
            anchorOrigin={anchorOrigin}
            sx={popoverStyle()}
        >
            <div style={popoverContainerStyle(context.theme)}>
                <div style={popoverHeaderStyle(context.theme)}>
                    <div style={popoverHeaderTitleContainerStyle()}>
                        <AccountCircle style={{ color: context.theme.top_menu.font }}/>
                        <Typography variant="h5" >Account</Typography>
                    </div>
                    <IconButton
                        style={{ color: context.theme.top_menu.font, marginRight: "1vw" }}
                        onClick={() => {setAnchor(null)}}
                    >
                        <Close/>
                    </IconButton>
                </div>
                <div style={popoverBodyStyle(context.theme)}>
                    <div style={{...twoBlockBox(), flexWrap: "wrap"}}>
                        <div>
                            <Typography variant="body1">{context.name}</Typography>
                            <Typography variant="body2"><i>{context.tenant_name} - {context.group_name ? context.group_name : "No Group"}</i></Typography>
                        </div>
                        <div style={{ textAlign: "end" }}>
                            <Typography variant="body1">{context.role_name}</Typography>
                            <Typography variant="body2"><i>{context.line_manager ? "Line Manager" : "Staff"}</i></Typography>
                        </div>
                    </div>
                    <div style={centerAlignSubBoxStyle()}>
                        <Button
                            sx={glassButtonStyle(context.theme)}
                            variant="contained"
                            onClick={() => {window.location = "https://www.google.com"}}
                        >
                            View Profile
                        </Button>
                    </div>
                    <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "column", gap: "1vh", alignItems: "center", justifyContent: "center" }}>
                        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center", gap: "1vw", width: "100%" }}>
                            {
                                context.attendance_access && (
                                    <AttendSection context={context} />
                                )
                            }
                            <SignatureSection context={context} />
                        </div>
                        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center", gap: "1vw", width: "100%" }}>
                            <TimekeeperSection
                                context={context}
                                timekeeper={timekeeper}
                                setTimekeeper={setTimekeeper}
                            />
                            <LogoutButton
                                context={context}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Popover>
    )
}