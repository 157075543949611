const pusher_client_id = "c5350c80e013ebed7175";
const pusher_region = "eu";

const staging = true;

const portal_url = staging ? "https://unity.portal.fe.staging.mybe.software" : "https://unity.portal.mybe.software";

module.exports = {
  pusherClientId: pusher_client_id,
  pusherRegion: pusher_region,
  staging: staging,
  portal_url: portal_url
};
